<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <router-link class="btn btn-add" :to="{name: 'admin.menuitem.add'}" v-if="checkPermissions(['menu.create'])">
          {{ $t('Menu item toevoegen') }}
        </router-link>
      </div>
    </header>

    <div class="main-content">
      <div class="pane">
        <formulate-input label="Zoeken" @input="filterMenuItems" type="text"/>
        <vue-nestable v-model="menuItemsFiltered" @change="menuOrder">
          <template slot-scope="{ item }" :class="item.id">
            <div :class="!item.children || item.children.length == 0 ? 'no-children' : ''" @click="collapseMenu" class="flex items-center">
              <div class="wrap">
                <VueNestableHandle :item="item">
                  <img class="mr-2" width="14" src="@/assets/img/move.svg" alt="move">
                </VueNestableHandle>

                <router-link
                  :to="{name: 'admin.menuitem', params: {id: item.id}}">
                <span @contextmenu.prevent.stop="menuItemRightClick($event, item)">
                  {{ getTranslation(item).name }}
                </span>
                </router-link>
              </div>

              <div v-if="item.children && item.children.length > 0" class="collapse-button">
                <img width="22" src="@/assets/img/chevron.svg" alt="collapse">
              </div>
            </div>
          </template>
        </vue-nestable>
      </div>
    </div>

    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from 'vue-nestable'
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: {
    PageTitle,
    VueNestable,
    VueNestableHandle
  },
  data () {
    return {
      isLoading: true,
      contextMenuOptions: [{ id: 'deleteMenuItem', name: 'Menu item verwijderen' }],
      menuItems: [],
      menuItemsFiltered: []
    }
  },
  async mounted () {
    this.isLoading = true
    await this.fetchMenuItems()
    this.isLoading = false
  },
  methods: {
    async filterMenuItems (search) {
      this.isLoading = true
      this.menuItemsFiltered = this.filterItems(JSON.parse(JSON.stringify(this.menuItems)), search)
      this.isLoading = false
    },
    filterItems (menuItems, search) {
      const _this = this
      const items = []

      for (const menuItem of menuItems) {
        if (menuItem.children && menuItem.children.length > 0) {
          menuItem.children = _this.filterItems(menuItem.children, search)
        }

        const find = menuItem.translations.find(translation => translation.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)

        if (find || menuItem.children.length > 0) {
          items.push(menuItem)
        }
      }

      return items
    },
    collapseMenu (e) {
      const item = e.target.closest('.nestable-item')
      // const list = item.querySelector('.nestable-list')
      item.classList.toggle('collapsed')
    },
    menuOrder: async function (value, options) {
      const items = this.updateParentIds(options.items)
      await ApiService.saveMenuItems(items)
    },
    updateParentIds: function (items, parent = null) {
      const _this = this
      const updatedItems = []

      for (let i = 0; i < items.length; i++) {
        const item = JSON.parse(JSON.stringify(items[i]))
        item.parent_id = null
        item.children = []
        item.weight = (i + 1)
        // item.roles = item.roles.map(role => role.id)
        if (parent) {
          item.parent_id = parent.id
        }

        if (items[i].children.length > 0) {
          item.children = _this.updateParentIds(items[i].children, items[i])
        }

        updatedItems.push(item)
      }

      return updatedItems
    },
    menuItemRightClick: async function (event, menuItem) {
      if (this.checkPermissions(['menu.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, menuItem)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteMenuItem(event.item.id)
      await this.fetchMenuItems()
    },
    fetchMenuItems: async function (search) {
      const response = await ApiService.fetchMenuItems({ search: search })
      this.menuItems = response.data
      this.menuItemsFiltered = response.data
    },
    deleteMenuItem: async function (id) {
      const response = await ApiService.deleteMenuItem(id)

      if (response.status === 200) {
        notification('Menu item succesvol verwijderd!')
      }
    }
  },
  computed: {}
}
</script>
